.projectlist {
  background-color: #3c493f;
  border-radius: 25px;
  height: 80%;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  overflow-y: scroll;
}
