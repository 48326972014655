.timeline-item {
  display: flex;
  flex-direction: column;
  background-color: green;
  padding: 0 0 0 0;
  justify-content: flex-end;
  width: 30%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: #b3bfb8;
  font-family: "Nova Round", cursive;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
}

.timeline-item-content {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  align-items: flex-start;
  position: relative;
}

.timeline-item-content::after {
  content: "";
  background-color: #b3bfb8;
  transform: rotate(45deg);
  right: -27.5px;
  top: calc(50% - 7.5px);
  width: 15px;
  height: 15px;
  position: absolute;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -27.5px;
}

.tag_time {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.tag {
  color: #f0f7f4;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  text-transform: uppercase;
}

.career-date {
  color: black;
  font-size: 13px;
}

.career-description {
  color: black;
  font-size: 20px;
}

.circle {
  font-size: 15px;
}

@media (max-width: 750px), (max-height: 750px) {
  .tag {
    font-size: 14px;
  }
  .career-date {
    font-size: 12px;
  }
  .career-description {
    font-size: 17px;
  }
  .circle {
    font-size: 13px;
  }
  .timeline-item-content::after {
    width: 0px;
    height: 0px;
  }
  .timeline-item {
    align-self: center;
    width: 45vw;
  }
  .timeline-item:nth-child(odd) {
    align-self: center;
    width: 45vw;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .tag {
    font-size: 12px;
  }
  .career-date {
    font-size: 10px;
  }
  .career-description {
    font-size: 15px;
  }
  .circle {
    font-size: 12px;
  }
  .timeline-item-content::after {
    width: 0px;
    height: 0px;
  }
  .timeline-item {
    align-self: center;
    width: 45vw;
  }
  .timeline-item:nth-child(odd) {
    align-self: center;
    width: 45vw;
  }
}
