.contact-icon {
  color: #3c493f;
  background-color: #b3bfb8;
  background-color: #b3bfb8;
  font-size: 40px;
  padding: 10px;
  border-radius: 50%;
}

.contact-icon:hover {
  color: #f0f7f4;
}

.contact-icon-list {
  margin: auto;
  display: flex;
  gap: 60px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 0 0 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}

li {
  font-size: 30px;
}

li > * {
  flex: 1 1 0;
}

h2 {
  color: #b3bfb8;
  margin: 0 0 0 0;
  font-size: 55px;
  font-family: "Nova Round", cursive;
}

.contact {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

@media (max-width: 750px), (max-height: 750px) {
  .contact-icon {
    font-size: 30px;
    padding: 7px;
  }
  .contact-icon-list {
    gap: 40px;
  }
  h2 {
    font-size: 45px;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .contact-icon {
    font-size: 20px;
    padding: 4px;
  }
  .contact-icon-list {
    gap: 25px;
  }
  h2 {
    font-size: 35px;
  }
}
