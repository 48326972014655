.profile-pic {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.about {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  overflow: scroll;
  padding: 20px;
}
.profile-box {
  border: 0 0 0 0;
  margin: auto;
  padding: 0 5% 0 5%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #3c493f;
  padding: 20px;
  border-radius: 20px;
}

.hi {
  color: #f0f7f4;
  font-size: 30px;
  width: 60vw;
  font-family: "Nova Round", cursive;
}

@media (max-width: 750px), (max-height: 750px) {
  .profile-box {
    gap: 5vh;
  }
  .profile-pic {
    height: 150px;
    width: 150px;
  }
  .hi {
    font-size: 25px;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .profile-box {
    gap: 3vh;
  }
  .hi {
    width: 90%;
    font-size: 20px;
  }
}
