.App {
  text-align: center;
  display: grid;
  grid-gap: 1vh;
}

.ContentBox {
  padding: 0 0 0 0;
  border: 0 0 0 0;
  margin: 0 0 0 0;
  height: 80vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(51, 49, 49);
}

/* Color palette {
  Kombu Green: 3C493F
  Xanadu: 7E8D85
  AshGray: B3BFB8
  Mint Cream: F0F7F4
  Magic Mint: A2E3C4
} */
