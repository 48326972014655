form {
  display: grid;
  gap: 1vh;
  margin: auto;
  align-items: center;
  align-content: space-around;
  justify-content: center;
  padding: 10px;
  height: 50vh;
  width: 52vw;
  background-color: #3c493f;
  border-radius: 20px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Nova Round", cursive;
  overflow-y: scroll;
}

label {
  font-size: 25px;
  color: #f0f7f4;
  justify-self: start;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  height: max-content;
  align-self: flex-start;
}

input,
textarea {
  height: 2.4vh;
  background-color: #b3bfb8;
  width: 50vw;
}

.message {
  height: 20vh;
}

.send-button {
  width: max-content;
  height: max-content;
  padding: 5px 20px 5px 20px;
  font-size: 18px;
  align-items: center;
  justify-items: center;
  justify-self: end;
  font-family: "Nova Round", cursive;
}

.label_input {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  height: max-content;
}

@media (max-width: 750px), (max-height: 750px) {
  label {
    font-size: 20px;
  }
  .send-button {
    font-size: 12px;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  label {
    font-size: 15px;
  }
  .send-button {
    font-size: 9px;
  }
}
