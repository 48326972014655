.project {
  background-color: #b3bfb8;
  width: 45vw;
  height: 25vh;
  margin: auto;
  height: max-content;
  border-radius: 25px 25px 0 25px;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family: "Nova Round", cursive;
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 25px 25px 0 25px;
}

.project-title {
  background-color: #7e8d85;
  color: #f0f7f4;
  max-width: max-content;
  border-radius: 0 25px 25px 0;
  padding: 0 1vw 0 1vw;
  font-size: 25px;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 0.5vw;
}

.project-tag {
  background-color: #3c493f;
  color: #f0f7f4;
  padding: 3px 5px 3px 5px;
  border-radius: 15px;
}

.project-description {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  padding: 5px;
  color: black;
  font-size: 20px;
  justify-content: center;
}

.project-learnmore {
  align-self: flex-end;
  font-size: 15px;
  margin-right: 1vw;
  margin-left: 1vw;
}

.project-description-readmore:hover {
  background-color: #3c493f;
  color: #f0f7f4;
}

.project-description-readmore {
  background-color: #b3bfb8;
  color: #3c493f;
  font-size: 15px;
}

.project-title-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 5px;
  font-size: 18px;
}

@media (max-width: 750px), (max-height: 750px) {
  .project-description {
    font-size: 15px;
  }
  .project-title {
    font-size: 20px;
  }
  .project-title-date {
    font-size: 14px;
  }
  .project-tag {
    font-size: 12px;
  }
  .project-learnmore {
    font-size: 12px;
  }
  .project-description-readmore {
    font-size: 12px;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .project-description {
    font-size: 12px;
  }
  .project-title {
    font-size: 15px;
  }
  .project-title-date {
    font-size: 10px;
  }
  .project-tag {
    font-size: 9px;
  }
  .project-learnmore {
    font-size: 9px;
  }
  .project-description-readmore {
    font-size: 9px;
  }
}
