.timeline-container {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0;
  width: 60%;
  position: relative;
  background-color: #3c493f;
  margin: auto;
  height: 80%;
  overflow-y: scroll;
  border-radius: 25px;
}

::-webkit-scrollbar {
  width: 0px;
}

.timeline-container > * {
  flex-shrink: 0;
}

.timeline-container::after {
  background-color: #f0f7f4;
  content: "";
  position: absolute;
  width: 4px;
  height: 110vh;
  left: calc(50% - 2px);
}

@media (max-width: 750px), (max-height: 750px) {
  .timeline-container::after {
    width: 0px;
    height: 0px;
  }
}
