@keyframes spin_words {
  10% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-102%);
  }
  35% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-202%);
  }
  60% {
    transform: translateY(-200%);
  }
  75% {
    transform: translateY(-302%);
  }
  85% {
    transform: translateY(-300%);
  }
  100% {
    transform: translateY(0%);
  }
}

.home-title {
  font-size: 100px;
  margin: 0 0 0 0;
  color: #f0f7f4;
  font-family: "Nova Round", cursive;
}

.tags1,
.tags2 {
  box-sizing: content-box;
  display: flex;
  height: 55px;
  font-size: 45px;
  background-color: #b3bfb8;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Nova Round", cursive;
}

.tags1 p,
.tags2 p {
  margin: 0;
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.moving-tags {
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  background-color: #3c493f;
}

.tags1 span,
.tags2 span {
  animation: spin_words 30s infinite;
  display: flex;
  justify-content: flex-start;

  color: #f0f7f4;
}

.tags2 span {
  animation: spin_words 25s infinite;
}

.home {
  padding: 0 0 0 0;
  border: 0 0 0 0;
  margin: 0 0 0 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 10%;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.home-icon {
  color: #3c493f;
  background-color: #b3bfb8;
  background-color: #b3bfb8;
  font-size: 40px;
  padding: 15px;
  border-radius: 50%;
}

.home-icon:hover {
  color: #f0f7f4;
}

.logo {
  height: 10vw;
  width: 10vw;
  border-radius: 50%;
}

.home-icon-list {
  margin: auto;
  display: flex;
  gap: 60px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 0 0 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 750px), (max-height: 750px) {
  .home-icon {
    font-size: 30px;
    padding: 12px;
  }
  .home-icon-list {
    gap: 40px;
  }
  .home-title {
    font-size: 70px;
  }
  .tags1,
  .tags2 {
    height: 35px;
    font-size: 30px;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .home-icon {
    font-size: 20px;
    padding: 8px;
  }
  .home-icon-list {
    gap: 25px;
  }
  .home-title {
    font-size: 40px;
  }
  .tags1,
  .tags2 {
    height: 6vw;
    font-size: 5vw;
  }
}
