.nav-all {
  display: flex;
  flex-direction: column;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
  background-color: #3c493f;
  color: #f0f7f4;
  position: relative;
}
.nav-toggle {
  display: none;
  width: max-content;
  height: max-content;
  position: absolute;
  right: 5%;
  top: calc(50%-10px);
}

.nav-toggle:hover {
  color: rgb(51, 49, 49);
}

.nav-home {
  font-size: 40px;
}

.nav-list-horizontal {
  display: flex;
  justify-content: center;
  gap: 2vw;
  align-items: center;
  width: 50%;
  list-style: none;
}

.nav-list-vertical {
  display: flex;
  gap: 1vh;
  align-items: center;
  width: max-content;
  list-style: none;
  flex-direction: column;
  background-color: #3c493f;
  padding: 0;
  margin: 0;
  align-self: center;
  width: 100%;
  display: none;
}

.nav-list-vertical li {
  font-size: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.link-style {
  color: #f0f7f4;
  font-size: 40px;
  text-decoration: none;
  padding: 2px 2px 2px 2px;
  border-radius: 10px;
}

.link-style-home {
  color: #f0f7f4;
  text-decoration: none;
}

.link-style:hover {
  color: black;
  background-color: #f0f7f4;
}

.link-style:focus {
  color: black;
  background-color: #f0f7f4;
}

@media (max-width: 750px), (max-height: 750px) {
  .nav-home {
    font-size: 30px;
  }
  .link-style {
    font-size: 30px;
  }
  .nav-toggle {
    display: unset;
    font-size: 20px;
  }
  .nav-list-horizontal {
    display: none;
  }
  .nav-list-vertical li {
    font-size: 25px;
  }
  .nav-list-vertical {
    display: flex;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .link-style {
    font-size: 20px;
  }
  .nav-home {
    font-size: 20px;
  }
  .nav-list-vertical li {
    font-size: 20px;
  }
  .nav-toggle {
    font-size: 15px;
  }
}
